import { useNavigate } from 'react-router-dom';

const FourOhFourRedirect = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate('/404');
  }, 10);

  return (
    <></>
  );
}

export default FourOhFourRedirect;