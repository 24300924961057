import React from 'react';
import './four-oh-four.scss';

class FourOhFour extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { };
  }

  componentDidMount(): void {
    document.title = '404 | In Oeuvre Our Heads';
  }

  render() {
    const backgroundVideo = require('./seagal-running.webm');

    return (
      <div className="four-oh-four-container">

        <div className="title-box">
          <div className="title">
            Whoops, that ain't right...
          </div>
          <div className="description">
            But not quite as not right as Steven Seagal's running style, am I right?
          </div>
          <a href="/" className="home-link">
            Retreat to Safety
          </a>
          <div className="error">
            404 Page Not Found
          </div>
        </div>

        <video
          className="background-video"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={backgroundVideo} type="video/webm" />
        </video>

      </div>
    );
  }

}

export default FourOhFour
