import React from 'react';
import './steven-see-it-all.scss';

class StevenSeeItAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount() {
    document.title = 'Steven See It All | In Oeuvre Our Heads';
  }

  render() {
    const banner = require('./assets/Banner.jpg');
    const cover = require('./assets/CoverImage.png');
    const aaronBabylonEssay = require('../../assets/papers/Aaron_Lynch_Babylon_Essay.docx');
    const ep36trial = require('./assets/ep36-plexburgh-trial.mp3');

    return (
      <div className="ssia-container">

        <img className="banner" src={banner} alt="Banner Image" />

        <h1 style={{marginBottom: '0'}}>Steven See It All</h1>
        <h3>An <a href="/">In Oeuvre Our Heads</a> Podcast</h3>

        <div className="text-block">
          <marquee>
            Have you ever wanted to subject yourself to the entire artistic oeuvre of one Mr. Steven Frederic Seagal? No. Of course you haven't. That's why we're doing it for you. Really it's just an excuse for 3 friends to hang out and ramble about how hilariously bad these movies are. Enjoy.
          </marquee>
        </div>

        <img className="cover-image" src={cover} alt="Cover Image"/>

        <center>
          _________________________________________________
        </center>

        <div className="text-block">
          <center>
            <a href="/steven-see-it-all/poster-game">
              <h2>Play the Poster Game!</h2>
            </a>
            If you've listened to the show, you'll know we're big fans of nitpicking the posters of these movies because they're almost all terrible. We thought it would be a fun challenge to see how many of them we remembered!
            <br /><br />
            So come one, come, all, check your SSIA knowledge and see what score you get!
          </center>
        </div>

        <center>
          _________________________________________________
        </center>

        <div className="text-block">
          <center>
            <a href={aaronBabylonEssay} download>
              <h2>Download Aaron's Babylon Essay</h2>
            </a>
            Aaron wrote a fantastic paper on the 2022 film, Babylon. You should read it.
          </center>
        </div>

        <center>
          _________________________________________________
        </center>

        <div className="text-block">
          <center>
            <h2>The Plexburgh Trial of Episode 37</h2>
            While you didn't hear it in the episode itself, we spent an hour and ten minutes talking about NOT the awful movie we had watched that week. This is a selection of that recording that we thought was extremely funny.
            <br /><br />
            <audio controls>
              <source src={ep36trial} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </center>
        </div>

        <center>
          _________________________________________________
        </center>

        <h2>Where to find us</h2>

        <div className="text-block">
          <center>
            Find us on Apple Podcasts, Pocket Casts, Spotify, Amazon Music I think?, and pretty much anywhere you can find podcasts.
          </center>
        </div>

        <a href="https://twitter.com/StevenSeeItAll?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @StevenSeeItAll</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <div style={{height: '20px'}}></div>
        <iframe style={{borderRadius: '12px', display: 'block', width: '400px', height: '152px'}} src="https://open.spotify.com/embed/show/05syD3vuiQR9CpWEsSf8rq?utm_source=generator" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

        <center>
          _________________________________________________
        </center>

        <div className="text-block disclaimer">
          <center>
            <b>Disclaimer:</b> We do not endorse Steven Seagal nor any of the heinous acts of which he is accused. We do not wish to boost his career, finances, or ego; we just aim to talk about his many very, very bad movies.
            <br /><br />
            We're watching them all so no one else ever has to.
          </center>
        </div>

        <a href="https://www.freecounterstat.com" title="Page Hits"><img src="https://counter10.optistats.ovh/private/freecounterstat.php?c=4ham9th1s2ukxgygwp74np9pe3rmmhrk" border="0" title="Page Hits" alt="Page Hits" /></a>

        <div className="text-block lol">
          <center>
            I built us a brand new flashy website, but I'm intentionally leaving the Steven See It All page exactly as it was... in all of it's 1997 web 1.0 styled glory.
          </center>
        </div>
      </div>
    );
  }

}

export default StevenSeeItAll
