import React from 'react';
import metadata from './metadata.json';
import './poster-game.scss';

class PosterGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'init',
      movies: metadata.movies,
      current: 1,
    };
  }

  componentDidMount() {
    document.title = 'The SSIA Poster Game! | In Oeuvre Our Heads';
  }

  initializeGame = () => {

    const randomizedMovies = this.shuffleArray(this.state.movies);

    this.setState((prevState) => ({
      ...prevState,
      status: 'in-progress',
      movies: randomizedMovies,
      current: 1,
      movieOptions: [],
      yearOptions: [],
      taglineOptions: [],
      selectedMovie: '',
      selectedYear: '',
      selectedTagline: '',
      answers: [],
      username: '',
    }));

    setTimeout(() => {
      this.setNewMovieOptions();
      this.setNewYearOptions();
      this.setNewTaglineOptions();
    }, 100);
  }

  shuffleArray = (arr) => {
    let array = JSON.parse(JSON.stringify(arr))
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  getCurrentMovie = () => {
    return this.state.movies[this.state.current - 1];
  };

  getPoster = (path) => {
    const poster = require(`./posters/${path ? path : this.getCurrentMovie()?.poster}`);
    if (poster) {
      return poster;
    }
    return null;
  };

  nextQuestion = () => {
    const updatedAnswers = this.state.answers;
    const newAnswer = {
      movie: this.getCurrentMovie(),
      selectedMovie: this.state.selectedMovie,
      selectedYear: this.state.selectedYear,
      selectedTagline: this.state.selectedTagline,
    }
    updatedAnswers.push(newAnswer);

    let newCurrent = this.state.current;
    let newStatus = this.state.status;
    if (this.state.current < 49) {
      newCurrent++;
    } else {
      newStatus = 'complete';
    }

    this.setState((prevState) => ({
      ...prevState,
      current: newCurrent,
      selectedMovie: '',
      selectedYear: '',
      selectedTagline: '',
      answers: updatedAnswers,
      status: newStatus,
    }));

    setTimeout(() => {
      this.setNewMovieOptions();
      this.setNewYearOptions();
      this.setNewTaglineOptions();
    }, 100);
  }

  handleMovieChange = (e) => {
    this.setState((prevState) => ({ ...prevState, selectedMovie: e?.target?.value ?? '' }));
  }

  handleYearChange = (e) => {
    this.setState((prevState) => ({ ...prevState, selectedYear: e?.target?.value ?? '' }));
  }

  handleTaglineChange = (e) => {
    this.setState((prevState) => ({ ...prevState, selectedTagline: e?.target?.value ?? '' }));
  }

  handleUsernameChange = (e) => {
    this.setState((prevState) => ({ ...prevState, username: e?.target?.value ?? '' }));
  }

  setNewMovieOptions = () => {
    const currentMovie = this.getCurrentMovie();
    let options = [];
    options.push(currentMovie.name);
    // lazy but it works
    let filteredMovies = this.state.movies.filter(x => x.id !== currentMovie.id);
    const op2 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op2.name);
    filteredMovies = this.state.movies.filter(x => x.id !== currentMovie.id && x.id !== op2.id);
    const op3 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op3.name);
    filteredMovies = this.state.movies.filter(x => x.id !== currentMovie.id && x.id !== op2.id && x.id !== op3.id);
    const op4 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op4.name);
    filteredMovies = this.state.movies.filter(x => x.id !== currentMovie.id && x.id !== op2.id && x.id !== op3.id && x.id !== op4.id);
    const op5 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op5.name);
    filteredMovies = this.state.movies.filter(x => x.id !== currentMovie.id && x.id !== op2.id && x.id !== op3.id && x.id !== op4.id && x.id !== op5.id);
    const op6 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op6.name);
    options = this.shuffleArray(options);
    this.setState((prevState) => ({ ...prevState, movieOptions: options }));
  };

  setNewYearOptions = () => {
    const currentMovie = this.getCurrentMovie();
    let options = [];
    options.push(currentMovie.year);
    // lazy but it works
    let filteredMovies = this.state.movies.filter(x => x.year !== currentMovie.year && x.id !== currentMovie.id);
    const op2 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op2.year);
    filteredMovies = this.state.movies.filter(x => x.year !== currentMovie.year && x.year !== op2.year && x.id !== currentMovie.id && x.id !== op2.id);
    const op3 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op3.year);
    filteredMovies = this.state.movies.filter(x => x.year !== currentMovie.year && x.year !== op2.year && x.year !== op3.year && x.id !== currentMovie.id && x.id !== op2.id && x.id !== op3.id);
    const op4 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op4.year);
    options = this.shuffleArray(options);
    this.setState((prevState) => ({ ...prevState, yearOptions: options }));
  };

  setNewTaglineOptions = () => {
    const currentMovie = this.getCurrentMovie();
    let options = [];
    options.push(currentMovie.phrase);
    // lazy but it works
    let filteredMovies = this.state.movies.filter(x => x.phrase !== '' && x.id !== currentMovie.id);
    const op2 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op2.phrase);
    filteredMovies = this.state.movies.filter(x => x.phrase !== '' && x.id !== currentMovie.id && x.id !== op2.id);
    const op3 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op3.phrase);
    filteredMovies = this.state.movies.filter(x => x.phrase !== '' && x.id !== currentMovie.id && x.id !== op2.id && x.id !== op3.id);
    const op4 = filteredMovies[Math.floor(Math.random()*filteredMovies.length)];
    options.push(op4.phrase);
    options = this.shuffleArray(options);
    this.setState((prevState) => ({ ...prevState, taglineOptions: options }));
  };

  render() {
    const correctMovies = this.state.answers?.filter(x => x.selectedMovie === x.movie.name)?.length ?? 0;
    const correctYears = this.state.answers?.filter(x => x.selectedYear === x.movie.year)?.length ?? 0;
    const correctTaglines = this.state.answers?.filter(x => x.selectedTagline === x.movie.phrase && x.movie.phrase !== '')?.length ?? 0;
    const moviePercent = Math.round((correctMovies/49)*100);
    const yearsPercent = Math.round((correctYears/49)*100);
    const taglinesPercent = Math.round((correctTaglines/45)*100);

    const getPercentColor = (percent) => {
      if (percent < 40) { return 'red' }
      else if (percent < 60) { return 'orange' }
      else if (percent < 80) { return 'yellow' }
      else if (percent <= 101) { return 'green' }
    };

    const getIcon = (a, b) => {
      if (a === b) {
        return (
          <img alt="correct" className="test-icon" src="https://upload.wikimedia.org/wikipedia/commons/3/3b/Eo_circle_green_checkmark.svg"/>
        );
      }
      return (
        <img alt="correct" className="test-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/5f/Red_X.svg"/>
      );
    };

    return (
      <div className="poster-game-container">

        {
          this.state.status === 'init' && (
            <>
              <h1>The Steven See It All Poster Game</h1>
              <p>
                We talk a lot about the miserable awful posters and irritating taglines of the Seagal movies we've tortured ourselves with.
              </p>
              <p>
                Let's see how much of that useless knowledge you've retained from listening to us ramble on about it!
              </p>
              <button onClick={() => this.initializeGame()}>START THE GAME!</button>
            </>
          )
        }

        {
          this.state.status === 'in-progress' && (
            <>
              <h1>Movie Poster {this.state.current}/49</h1>
              <img
                className="poster"
                src={this.getPoster()}
                alt={`Movie Poster ${this.state.current}/49`}
              />

              <select
                name="movie"
                id="movie"
                value={this.state.selectedMovie}
                onChange={this.handleMovieChange}
              >
                {
                  <>
                    <option value=''>Select a Movie</option>
                    {
                      this.state.movieOptions.map(op => (
                        <option value={op}>{op}</option>
                      ))
                    }
                  </>
                }
              </select>

              <select
                name="year"
                id="year"
                value={this.state.selectedYear}
                onChange={this.handleYearChange}
              >
                {
                  <>
                    <option value=''>Select a Year</option>
                    {
                      this.state.yearOptions.map(op => (
                        <option value={op}>{op}</option>
                      ))
                    }
                  </>
                }
              </select>

              <select
                name="tagline"
                id="tagline"
                value={this.state.selectedTagline}
                onChange={this.handleTaglineChange}
                disabled={this.getCurrentMovie().phrase === ''}
              >
                {
                  <>
                    <option value=''>{this.getCurrentMovie().phrase === '' ? 'No Tagline for this movie' : 'Select a Tagline'}</option>
                    {
                      this.state.taglineOptions.map(op => (
                        <option value={op}>{op}</option>
                      ))
                    }
                  </>
                }
              </select>

              <div>___________________________________________________________</div>

              <button onClick={() => this.nextQuestion()}>Next</button>
            </>
          )
        }

        {
          this.state.status === 'complete' && (
            <>
              <h1>Done and Done!</h1>
              <p>
                Let's see how you did
              </p>
              <h2>
                Movie Names: {correctMovies}/49
                <span className={`percent ${getPercentColor(moviePercent)}`}>{moviePercent}%</span>
              </h2>
              <h2>
                Release Years: {correctYears}/49
                <span className={`percent ${getPercentColor(yearsPercent)}`}>{yearsPercent}%</span>
              </h2>
              <h2>
                Movie Taglines: {correctTaglines}/45
                <span className={`percent ${getPercentColor(taglinesPercent)}`}>{taglinesPercent}%</span>
              </h2>

              <h1>
                Final Score
                <span className={`percent ${getPercentColor(Math.round((moviePercent * 0.5)+(yearsPercent * 0.2)+(taglinesPercent * 0.3)))}`}>
                  {Math.round((moviePercent * 0.5)+(yearsPercent * 0.2)+(taglinesPercent * 0.3))}%
                </span>
              </h1>
              Composite Score
              <br />
              (Name * 0.5) + (Year * 0.2) + (Tagline * 0.3)
              <br />
              Rounded to the nearest whole number

              <a href="/steven-see-it-all">Return to StevenSeeItAll.com</a>

              <div>___________________________________________________________</div>

              {
                this.state.answers?.map(answer => (
                  <>
                    <h1>{answer.movie.name} ({answer.movie.year})</h1>
                    <h3>{answer.movie.phrase}</h3>
                    <img
                      className="poster small"
                      src={this.getPoster(answer.movie.poster)}
                      alt={`${answer.movie.name}`}
                    />
                    <h2>Your Answers</h2>
                    <p className="left-bound">
                      {getIcon(answer.movie.name, answer.selectedMovie)}
                      <b>Name:</b> {answer.selectedMovie}
                      <br />
                      {getIcon(answer.movie.year, answer.selectedYear)}
                      <b>Year:</b> {answer.selectedYear}
                      <br />
                      {getIcon(answer.movie.phrase, answer.selectedTagline)}
                      <b>Tagline:</b> {answer.selectedTagline}
                    </p>
                    <div>___________________________________________________________</div>
                  </>
                ))
              }
            </>
          )
        }

      </div>
    );
  }

}

export default PosterGame
