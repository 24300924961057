import React from 'react';
import { Routes, Route } from "react-router-dom"
import InOeuvreOurHeads from './routes/in-oeuvre-our-heads/in-oeuvre-our-heads';
import StevenSeeItAll from './routes/steven-see-it-all/steven-see-it-all';
import PosterGame from './routes/steven-see-it-all/poster-game/poster-game';
import FourOhFour from './error-routes/four-oh-four/four-oh-four';
import FourOhFourRedirect from './error-routes/four-oh-four/four-oh-four-redirect';
import './App.scss';

function App() {
  return (
    <div className="app-container">
      <Routes>
        <Route
          path="/"
          element={<InOeuvreOurHeads />}
        />
        <Route
          path="/steven-see-it-all"
          element={<StevenSeeItAll />}
        />
        <Route
          path="/steven-see-it-all/poster-game"
          element={<PosterGame />}
        />
        <Route
          path="/ssia"
          element={<StevenSeeItAll />}
        />
        <Route
          path="/404"
          element={<FourOhFour />}
        />
        <Route
          path="*"
          element={<FourOhFourRedirect />}
        />
      </Routes>
    </div>
  );
}

export default App;
