import React from 'react';

class InOeuvreOurHeads extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { };
  }

  componentDidMount(): void {
    document.title = 'In Oeuvre Our Heads';
  }

  render() {
    return (
      <center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>In Oeuvre Our Heads</h1>
        <h2>Under Construction</h2>
        <a href="/steven-see-it-all">Check out Steven See It All</a>
      </center>
    );
  }

}

export default InOeuvreOurHeads
